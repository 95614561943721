@font-face {
  font-family: 'Inter';
  src: url('./static/fonts/Inter-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open sans';
  src: url('./static/fonts/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
p, td{
  cursor: default;
}
h1{
  line-height: 1.2!important
}
body {
  margin: 0;
  font-family: 'Inter';
  overflow-x: hidden;
}
.bold{
  font-weight: bold;
}
.Requied{
  color: #32A699;
}
.btn{
  border-radius: 33px;
  height: 45px;
  width: 190px;
  font-size: 13px;
}
.Text-center{
  text-align: center;
}
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: 100%;
}
.btn-primary, .btn-primary:focus, .btn-primary:focus-visible, .btn-primary:focus-within{
  background: transparent linear-gradient(255deg, #39B8B8 0%, #39B8B8 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #32A699;
  color: #fff;
}
.btn-primary:hover,.btn-primary:active{
  background: transparent linear-gradient(77deg, #39B8B8 0%, #36DDDD 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #32A699;
  color: #fff;
}
.btn-primary:disabled{
  background: #9CA9B5 0% 0% no-repeat padding-box;
  border: 1px solid #9CA9B5;
  color: #fff;
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:focus-visible, .btn-secondary:focus-within{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #39B8B8;
  color: #39B8B8;
}
.btn-secondary:hover, .btn-secondary:active{
  background: #E8F5EC 0% 0% no-repeat padding-box;
  border: 1px solid #39B8B8;
  color: #39B8B8;
}
.btn-secondary:disabled{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #6D7680;
  color: #9CA9B5;
}

.alert-success{
  color: #659134;
  background-color: #f1f6ed;
  border-color: #f1f6ed;
}
.table-light{
  --bs-table-bg: none;
  --bs-table-striped-bg: none;
  --bs-table-striped-color: none;
  --bs-table-active-bg: none;
  --bs-table-active-color: none;
  --bs-table-hover-bg: none;
  --bs-table-hover-color: none;
  background: #A0BCCE26 0% 0% no-repeat padding-box;
  border-color: #A0BCCE26;
}
.table>:not(:first-child){
    border-top: none;
}
.TdwithIcon{
  display: flex;
  align-items: center;
}
.TdwithIcon img{
  margin-right: 6px;
}
.reference{
  color: #39B8B8;
  font-weight: bold;
  margin-bottom: 0px;
  cursor: pointer;
}
.reference2{
  color: #39B8B8;
  font-weight: bold;
  margin-bottom: 0px;
}
.SpinnerLoading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.SpinnerLoading .spinner-border{
  color: #32A699;
}
/* Swal Style*/
.swal2-actions .swal2-styled.swal2-confirm {
  background: transparent linear-gradient(255deg, #39B8B8 0%, #39B8B8 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #32A699;
  color: #fff;
  border-radius: 100px;
  width: 190px;
  font-size: 15px;
}
.swal2-actions .swal2-styled.swal2-cancel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #39B8B8;
  color: #39B8B8;
  border-radius: 100px;
  width: 190px;
  font-size: 15px;
}
.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:hover,.swal2-popup .swal2-actions .swal2-styled.swal2-confirm:active{
  background: transparent linear-gradient(77deg, #39B8B8 0%, #36DDDD 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #32A699;
  color: #fff;
}
.swal2-popup .swal2-title{
  font-family: 'Inter';
  font-size: 21px;
}
.order-1 { order: 1; }
.order-2 { order: 2; }
.swal2-icon.swal2-warning{
  border-color: #f27474!important;
  color: #f27474!important;
}
.reduce{
  text-transform: lowercase;
}
@media screen and (max-width: 1100px) {
  .btn {
    width: 170px;
    font-size: 11px;
  }
}
@media (min-width: 1100px){
  .modal-lg, .modal-xl {
    max-width: calc(100% - 200px);
  }
}
/*@media (min-width: 1600px){
  .btn {
    font-size: 18px;
    height: 60px;
    width: 260px;
  }
}*/