.Header{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.Header h1{
    font-weight: 100;
    font-size: 28px;
    margin-bottom: 0px;
}
.Header p{
    font-weight: 600;
    font-size:  15px;
    margin: 0px 20px;
}
.Header .profile{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.Header .profile img{
    cursor: pointer;
}
.Header .profile img.picture{
    cursor: auto;
    border: 3px solid #39B8B8;
}
.align-center{
    align-items: center;
}
.SpinnerPage{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.SpinnerPageDetail{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.spinner-border{
    width: 8rem;
    height: 8rem;
}
.CircularProgressbar{
    width: 200px!important;
}
/*@media (min-width: 1600px){

    .Header{
        padding: 25px 20px;
    }
    .Header h1{
        font-size: 32px;
    }
    .Header p{
        font-size:  20px;
    }
  }*/